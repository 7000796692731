@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: aqua; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  min-height: 100%;
  /* font-family: Roboto,sans-serif; */
}
.MuiGrid-spacing-xs-3{
  padding: 0 !important;
}
#root{
  min-height: 100%;
}
.App {
  text-align: center;
  /* padding: 75px 0 0 55px; */
  min-height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.searchButton{
    float: left;
    font-family: Roboto, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #002FC5;
    margin-left: 20px;
}



  .no-branch-offices{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 229px;
    background: #FFFFFF;
  }
  
  .no-branch-offices img{
    width: 136px;
    height: 136px;
  }
  
  .no-branch-offices p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */
    
    letter-spacing: 0.15px;
    
    color: #3D3F4C;
    text-shadow: 0px 0 #3D3F4C;
  }
  
  .subtitle{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    /* identical to box height, or 32px */
  
    letter-spacing: 0.15px;
  
    /* text/primary */
  
    color: rgba(0, 0, 0, 0.87);
    text-shadow: 0px 0 rgba(0, 0, 0, 0.87);
  
    display: flex;
    justify-content: flex-start;
  }
  .add-branch-office, .add-shedule{
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .branchoffices-btns{
    margin-top: 55px ;
    margin-bottom: 30px ;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .branchoffices-btns button{
    margin-left: 25px ;
  }

  .title{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 133.4%;
    display: flex;
    align-items: center;
    color: #252525;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 15px 0px 35px 0px;
  }

  .add-branch-office button{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    text-shadow: 0px 0 #002FC5;
    margin: 8px 0px;
    
  }
  .add-shedule button{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.46px;
    text-transform: uppercase;
    text-shadow: 0px 0 #002FC5;
    margin: 8px 0px;
    
  }

  .MuiAccordion-rounded{
    background: #FFFFFF;
    border-radius: 4px 4px 0px 0px !important;
  }
  
  #panel-header{
    
    border-bottom: 1px solid #E5E5E5;
    
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-shadow: 0px 0 rgba(0, 0, 0, 0.87);
  
  }
  
  .MuiAccordionDetails-root form{
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    width: 100%;
  }
  .MuiTextField-root{
    width: 100%;
  
  }
  /* .MuiAccordion-root.Mui-expanded{
    margin: 25px 0 !important;
  } */
  /* .MuiCard-root{
    margin: 25px 0 !important;
  } */
  .MuiCardContent-root:last-child {
    padding-bottom: 10px !important;
  }
  .item-title{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
    text-shadow: 0px 0 rgba(0, 0, 0, 0.87);
  }
 
/* .MuiDialog-paperWidthSm {
    max-width: fit-content !important;
} */
